import React, { useEffect } from "react"
import { useIdentityContext } from "../services/react-netlify-identity-gotrue"
import { useSubInfoContext } from "../services/subinfocontext"
import Layout from "../components/common/layout/layout"
import ResetPasswordRequestForm from "../components/user/resetpasswordreqform"
import { navigate } from "gatsby"

const SIBResetPasswordRequestPage = ({ location }) => {
  const identity = useIdentityContext()
  const subInfoContext = useSubInfoContext()

  // renavigate if logged in
  useEffect(() => {
    if (subInfoContext.subInfo) {
      if (subInfoContext.subInfo.isStudentCode) {
        navigate("/studenthome")
      }
    }
  }, [subInfoContext.subInfo, identity.user])

  // and users once logged in
  useEffect(() => {
    if (subInfoContext.subInfo) {
      if (identity.user) {
        navigate("/app/userhome")
      }
    }
  }, [subInfoContext.subInfo, identity.user])

  return (
    <Layout>
      <SIBResetPasswordRequestHeader />
      <div className="container user-login-form-container">
        <div className="columns">
          <div className="column is-8-desktop is-offset-2-desktop">
            <ResetPasswordRequestForm />
          </div>
        </div>
      </div>
    </Layout>
  )
}

const SIBResetPasswordRequestHeader = (props) => {
  return (
    <>
      <section className="page-header bg-paper bg-tear-white">
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <h1>Password Reset</h1>
              <div>Please enter the email address associated with your login details. If the address matches our records, you will be sent an email with further instructions.</div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SIBResetPasswordRequestPage

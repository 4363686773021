import React, { useState } from "react"
import { useIdentityContext } from "../../services/react-netlify-identity-gotrue"
import { useForm } from "react-hook-form"
import { Link } from "gatsby"

import Loader from "react-loader-spinner"

export default function ResetPasswordRequestForm({ navigateTarget }) {
  const identity = useIdentityContext()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ reValidateMode: "onSubmit" })

  const [formError, setFormError] = useState(false)
  const [formSuccess, setFormSuccess] = useState(false)

  const [requesting, setRequesting] = useState(false)

  const onSubmit = async (data) => {
    setRequesting(true)
    setFormError(false)
    identity
      .sendPasswordRecovery({
        email: data.email,
      })
      .then(() => {
        setRequesting(false)
        setFormSuccess(true)
      })
      .catch((e) => {
        setFormError(e.message)
        setRequesting(false)
      })
  }

  return (
    <>
      <div className={`${formSuccess ? "" : "is-hidden"}`}>
        <div className="container has-text-centered pt-6">
          <p>
            Your password reset request has been submitted. If the provided email
            matched our records, a password reset link will be sent to the
            provided email.
          </p>
          <p className="mt-1">
            Please check your spam folder if you don't have a reset email. Also note that you must wait 15 minutes between password reset requests.
          </p>
          <div className="block py-5 has-text-sibredorange">
            <Link className="button is-black" to="/login">
              Back to Login
            </Link>
          </div>
        </div>
      </div>
      {formError && (
        <div className="notification-error-text py-1">{formError}</div>
      )}
      <div className={`${requesting || formSuccess ? "is-hidden" : ""}`}>
        <form id="pw-req-submit-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="py-1">Registered Email Address</div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                {...register("email", {
                  required: true,
                  maxLength: 100,
                  minLength: 5,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
                className="input"
                type="text"
                placeholder="Email"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-envelope"></i>
              </span>
              <span className="notification-error-text">
                {errors.email && errors.email.type === "required" && (
                  <span>Email is required</span>
                )}
                {errors.email && errors.email.type === "maxLength" && (
                  <span>Max Length Exceeded (100)</span>
                )}
                {errors.email && errors.email.type === "minLength" && (
                  <span>Minimum 5 Characters</span>
                )}
                {errors.email && errors.email.type === "pattern" && (
                  <span>Please enter a valid Email address</span>
                )}
              </span>
            </p>
          </div>
          <button className="button is-black is-fullwidth mt-4" type="submit">
            Request password reset
          </button>
        </form>
      </div>
      <div className={`${requesting ? "" : "is-hidden"}`}>
        <div className="has-text-centered my-2">
          <Loader type="TailSpin" color="#000000" height={100} width={100} />
        </div>
        <div className="title is-5 has-text-centered mt-3">
          A Moment, Please
        </div>
      </div>
    </>
  )
}
